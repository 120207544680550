<template>
    <div  class="d-flex gap-3 flex-column">
        <h1 class="fw-bold">Profile</h1>
        <div class="d-flex p-0 flex-wrap">
            <span>Tatsing Group > Profile</span>

            <span class="fw-bold m-auto" style="font-size: large;"
            :class="{'text-success': message.includes('uccess'), 'text-danger': !message.includes('uccess')}"
            v-show="message">{{ message }}</span>
        </div>

        <div class="d-flex flex-column flex-md-row justify-content-between gap-5">
            <div class="h-auto">
                <PreloaderVue v-show="isLoading" />
                <div class="w-100 d-flex flex-column gap-2 card rounded shadow-sm py-3 px-5">
                    <img :src="file_data ? file_data : decoded_photo" v-show="(file_data || decoded_photo) && !notanImage" alt="profile-photo"
                    class="mx-auto rounded-circle shadow-sm" style="width: 125px; height:125px">

                    <span v-if="!user_data.profile_photo" :class="{'d-none': file_data && !user_data.profile_photo}" class="mx-auto rounded-circle shadow-sm bg-muted text-center fw-bold text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                        </svg>
                    </span>
                    
                    <span v-show="notanImage" class="mx-auto rounded-circle shadow-sm bg-danger text-center fw-bold text-white py-4"
                    style="width: 125px; height:125px;">Please pick a valid Image</span>
                    
                    <div class="d-flex flex-column mx-auto">
                        <span class="fw-bold text-center">Profile Photo</span>
                        <span class="text-center">{{ photo_properties.name }}</span>
                    </div>
                    <button type="button" class="btn bg-theme text-white py-2 px-2 mx-auto rounded border-0"
                        style="width: 150px;" @click="photo_edit_clicked">Edit Picture</button>
                    <form class="mx-auto" enctype="multipart/form-data" @submit.prevent="upload">
                        <input ref="upload" name="uploadfile" type="file" accept="image/*" class="d-none" @change="pick_photo">
                        <button type="submit"
                            class="btn bg-theme text-white py-2 px-2 mx-auto rounded border-0" style="width: 150px;"
                            :disabled="!to_editPhoto">Save Picture</button>
                    </form>
                    <span id="file" class="text-theme text-center mx-auto"
                        style="font-size: small; width: 100px;"></span>
                </div>
            </div>

            <div class="w-100 d-flex flex-column gap-5">
                <form @submit.prevent="updateProfile">
                    <div class="card shadow-sm d-flex flex-column justify-content-center">
                        <div class="card py-3 px-4 d-flex flex-column flex-md-row gap-5">
                            <h4 class="fw-bold" style="font-size: larger;">Profile details</h4>
                        </div>
                        <div class="card">
                            <div class="d-flex align-items-center flex-column w-100">
                                <PreloaderVue v-show="isLoading" />
                                <div class="w-100 border-bottom px-4 py-2">
                                    <div class="text-danger align-middle" v-show="full_name_errors.length">
                                        <ul>
                                            <li v-for="error in full_name_errors" :key="error">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                            <span class="fw-bold">Name</span>
                                            
                                        </div>
                                        <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                                            <span id="name" style="font-size: small;" :class="{'d-none': to_editInfo}">
                                                {{ user_data.full_name }}
                                            </span>
                                                
                                            <input v-model="user_data.full_name" class="w-100" :class="{'d-none': !to_editInfo}"
                                            type="text" style="font-size: small;" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 border-bottom px-4 py-2">
                                    <div class="row">
                                        <div class="text-danger align-middle" v-show="email_errors.length">
                                            <ul>
                                                <li v-for="error in email_errors" :key="error">
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                            <span class="fw-bold">Email</span>
                                        </div>
                                        <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                                            <span id="email" style="font-size: small;" :class="{'d-none': to_editInfo}">
                                                {{ user_data.email }}
                                            </span>
                                            <input v-model="user_data.email" class="w-100" :class="{'d-none': !to_editInfo}"
                                            type="email" style="font-size: small;" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 border-bottom px-4 py-2">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                            <span class="fw-bold">Job Title</span>
                                        </div>
                                        <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                                            <span id="title" style="font-size: small;" :class="{'d-none': to_editInfo}">
                                                {{ user_data.job_title }}
                                            </span>
                                            <input v-model="user_data.job_title" class="w-100" :class="{'d-none': !to_editInfo}"
                                            type="text" style="font-size: small;" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 border-bottom px-4 py-2">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                            <span class="fw-bold">Department</span>
                                        </div>
                                        <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                                            <span style="font-size: small;">{{ user_data.department_name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card py-3 px-4">
                            <div class="ms-auto">
                                <button type="button" @click="info_edit_clicked"
                                    class="bg-theme text-white py-2 px-2 rounded border-0 mx-2"
                                    :class="{'opacity-75': !to_editInfo}" :disabled="!to_editInfo"
                                    style="width: 150px;">Close</button>
                                <button id="profile" type="button"
                                    class="bg-theme text-white py-2 px-2 rounded border-0" :class="{'d-none': to_editInfo}"
                                    style="width: 150px;" @click="info_edit_clicked">Edit Profile</button>
                                <button type="submit"
                                    class="bg-theme text-white py-2 px-2 rounded border-0" :class="{'d-none': !to_editInfo}"
                                    style="width: 150px;">Save Profile</button>
                            </div>
                        </div>
                    </div>
                </form>

                <form @submit.prevent="updatePassword">
                    <div class="card shadow-sm d-flex flex-column justify-content-center">
                        <div class="card py-3 px-4">
                            <h4 class="fw-bold" style="font-size: larger;">Security Details</h4>
                        </div>
                        <div class="card">
                            <PreloaderVue v-show="isLoading" />
                            <div class="d-flex align-items-center flex-column w-100">
                                <div class="w-100 border-bottom px-4 py-2">
                                    <div class="text-danger align-middle" v-show="password_errors.length">
                                        <ul>
                                            <li v-for="error in password_errors" :key="error">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                            <span class="fw-bold">Password</span>
                                        </div>
                                        <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                                            <span id="password" style="font-size: small;" :class="{'d-none': to_editPassword}">
                                                ***************
                                            </span>
                                            <input v-model="password_data.password" class="w-100" :class="{'d-none': !to_editPassword}"
                                                placeholder="Enter new password" type="password"
                                                style="font-size: small;" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 border-bottom px-4 py-2" v-show="to_editPassword">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                            <span class="fw-bold">Confirm Password</span>
                                        </div>
                                        <div class="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                                            <input v-model="password_data.password_confirmation" class="w-100"
                                                placeholder="Confirm new password" type="password"
                                                style="font-size: small;" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card py-3 px-4">
                            <div class="ms-auto">
                                <button type="button" @click="password_edit_clicked"
                                    class="bg-theme text-white py-2 px-2 rounded border-0 mx-2"
                                    :class="{'opacity-75': !to_editPassword}" :disabled="!to_editPassword"
                                    style="width: 150px;">Close</button>
                                <button type="button" @click="password_edit_clicked"
                                    class="bg-theme text-white py-2 px-2 rounded border-0" :class="{'d-none': to_editPassword}"
                                    style="width: 150px;">Change Password</button>
                                <button type="submit"
                                    class="bg-theme text-white py-2 px-2 rounded border-0" :class="{'d-none': !to_editPassword}"
                                    style="width: 150px;">Change Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
import { createNamespacedHelpers } from "vuex";
import PreloaderVue from "../../../components/Preloader.vue";
createNamespacedHelpers('user')

export default {
    name: "Profile",
    components: { PreloaderVue },
    data() {
        return {
            user_data: {},
            password_data: {
                password: "",
                password_confirmation: "",
            },
            decoded_photo: '',
            to_editInfo: false,
            to_editPassword: false,
            to_editPhoto: false,
            notanImage: false,
            file_data: '',
            photo_properties: '',
        };
    },
    beforeMount() {
        if(this.$store.getters['get_perm']('uselfUser')
            || this.$store.getters['get_perm']('updateUser')) return
        else this.$router.push('/dashboard')
    },
    async mounted() {
        this.$store.commit("user/reset_errors");
        this.$store.commit("user/reset_message");
        await this.$store.dispatch("user/getProfile").then(() => {
            Object.assign(this.user_data, this.$store.getters['user/GET_DATA']);
        });
        await this.$store.dispatch('user/getPhoto', { id: this.user_data.id, photo_name: this.user_data.profile_photo}).then(() => {
            this.decoded_photo = this.$store.state.user.profile_photo
        });
    },
    methods: {
        info_edit_clicked() {
            this.to_editInfo = !this.to_editInfo;
        },
        password_edit_clicked() {
            this.to_editPassword = !this.to_editPassword;
        },
        photo_edit_clicked() {
            this.$refs.upload.click()
        },
        async updateProfile() {
            var orig_user_data = this.$store.state.user.user_data;
            if (JSON.stringify(orig_user_data) == JSON.stringify(this.user_data)) {
                this.$store.commit("user/reset_errors");
                this.$store.commit("user/reset_message");
                this.to_editInfo = false;
                this.to_editPassword = false;
            }
            else {
                Object.assign(this.user_data, { _method: 'PATCH' })
                await this.$store.dispatch("user/updateUser", this.user_data).then(() => {
                    if (this.full_name_errors.length || this.email_errors.length)
                        return;
                    if (this.to_editInfo)
                        this.to_editInfo = false;
                    if (this.to_editPassword)
                        this.to_editPassword = false;
                });
            }
        },
        async updatePassword() {
            Object.assign(this.user_data, this.password_data);
            Object.assign(this.user_data, { _method: 'PATCH' })
            await this.$store.dispatch("user/updateUser", this.user_data).then(() => {
                if (this.password_errors.length)
                    return;
                if (this.to_editInfo)
                    this.to_editInfo = false;
                if (this.to_editPassword)
                    this.to_editPassword = false;
            });
        },
        pick_photo(event) {
            const files = event.target.files
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.file_data = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.photo_properties = files[0]
            if(!(this.photo_properties['type'].split('/')[0] === 'image')) {
                this.notanImage = true
            }
            else {
                this.notanImage = false
                this.to_editPhoto = true;
            }
        },
        async upload() {
            if(this.photo_properties['name']){
                var formData = new FormData();
                formData.append('file_data', this.photo_properties)
                await this.$store.dispatch("user/upload", formData).then(() => {
                    Promise.resolve(
                        Object.assign(this.user_data, { profile_photo: this.$store.state.user.profile_photo })
                    ).then(() => {
                        Object.assign(this.user_data, { _method: 'PATCH' })
                        this.$store.dispatch('user/updateUser', this.user_data).then(() => {
                            if(this.message.toLowerCase().includes('success')) this.$store.commit('user/change_sidebar_photo', this.file_data)
                        })
                    })
                })
            }
            return
        }
    },
    computed: {
        isLoading: {
            get() {
                return this.$store.state.user.isLoading;
            }
        },
        message: {
            get() {
                return this.$store.state.user.message;
            }
        },
        full_name_errors: {
            get() {
                var obj = this.$store.state.user.errors.errors;
                var name_error_list = [];
                if (Object.getOwnPropertyDescriptor(obj, "full_name") && obj.name) {
                    for (var key in obj.name) {
                        name_error_list.push(obj.name[key]);
                    }
                }
                return name_error_list;
            }
        },
        email_errors: {
            get() {
                var obj = this.$store.state.user.errors.errors;
                var email_error_list = [];
                if (Object.getOwnPropertyDescriptor(obj, "email") && obj.email) {
                    for (var key in obj.email) {
                        email_error_list.push(obj.email[key]);
                    }
                }
                return email_error_list;
            }
        },
        password_errors: {
            get() {
                var obj = this.$store.state.user.errors.errors;
                var password_error_list = [];
                if (Object.getOwnPropertyDescriptor(obj, "password") && obj.password) {
                    for (var key in obj.password) {
                        password_error_list.push(obj.password[key]);
                    }
                }
                return password_error_list;
            }
        },
    },
}
</script>